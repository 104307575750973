// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { LocalUserChoices } from '@livekit/components-core';
import { createContext } from 'react';

interface MediachoicesValues {
  userChoices: LocalUserChoices;
  saveAudioInputEnabled: (isEnabled: boolean) => void;
  saveVideoInputEnabled: (isEnabled: boolean) => void;
  saveAudioInputDeviceId: (deviceId: string) => void;
  saveVideoInputDeviceId: (deviceId: string) => void;
  saveUsername: (username: string) => void;
}

export const MediaChoicesContext = createContext<MediachoicesValues | undefined>(undefined);
